// 
// _table.scss
// 

.table {
  // --bs-table-hover-bg: #0A172C;
  // --bs-table-hover-color: #fff;
  th {
    font-weight: $font-weight-bold;
  }


  padding: .25rem;

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
  .text {
    color: $body-color;
  }

}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}


body[data-layout-mode="dark"] {

  // table 
  .table {
    border-color: lighten($gray-dark-200, 5%);
    color: $gray-dark-400;
  }

  .table-bordered {
    border-color: $gray-dark-300;

    th,
    td {
      border-color: $gray-dark-300;
    }
  }

  .table>:not(:last-child)>:last-child>* {
    border-bottom-color: $gray-dark-300;
  }

  .table-striped>tbody>tr:nth-of-type(odd),
  .table-hover>tbody>tr:hover,
  .table .table-light {
    --bs-table-accent-bg: #363a38;
  }

  // table
  .table-dark {
    background-color: $gray-dark-300;

    >:not(caption)>*>* {
      background-color: $gray-dark-300;
    }
  }
}

.custom-div {
  width: 90%;
  display: flex;
  justify-content: center;

}

.centerV {
  vertical-align: middle;
}

.table-wrapper{
  @extend .border;
  @extend .rounded-3;
  @extend .overflow-hidden;
}
.table-content{
  @extend .text-center;
  @extend .mb-0;
  
}
.table-head{
  @extend .table-light;
}
.table-head-item{
  @extend .color-primary;
}
.table-body{
  @extend .border-top;
}
.table-body-row{}
.table-body-item{
  @extend .centerV;
}

